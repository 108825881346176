import { createTheme } from "@mui/material";
import { themeCssBreakpoints } from "./cssBreakpoints";
import { createTypography } from "./typography-tokens";

export const defaultThemeTokens = {
  isDark: true,
  font: "Roboto",
  fontScale: 1.0,

  textPrimary: "#E5E6E9",
  textSecondary: "#BBBAC3",
  textDisabled: "#848592",

  backgroundDefault: "#0E0E16", //#0E0E16
  backgroundPaper: "#1C1D24", //#14141f //1C1D24 evi
  backgroundDialog: "#2B2C35", //#242438 //2B2C35 evi

  palette: {
    primary: "#4FC0E8",
    secondary: "#ED117D",
    info: "#78CEED",
    warning: "#FFCF55",
    error: "#EC5566",
    success: "#A2D465",

    release: "#AEEADB",
    pending: "#FFE299",
    draft: "#bbCF55",
  },

  // header: '#17171C',
  // sidenav: '#15151C',
};

const defaultTypography = createTypography();

export const defaultTheme = createTheme({
  palette: {
    mode: defaultThemeTokens.isDark ? "dark" : "light",
    primary: {
      main: defaultThemeTokens.palette.primary,
      light: "",
      dark: "",
    },
    secondary: {
      main: defaultThemeTokens.palette.secondary,
      light: "",
      dark: "",
    },
    error: {
      main: defaultThemeTokens.palette.error,
      light: "",
      dark: "",
    },
    warning: {
      main: defaultThemeTokens.palette.warning,
      light: "",
      dark: "",
    },
    info: {
      main: defaultThemeTokens.palette.info,
      light: "",
      dark: "",
    },
    success: {
      main: defaultThemeTokens.palette.success,
      light: "",
      dark: "",
    },
    background: {
      default: defaultThemeTokens.backgroundDefault,
      paper: defaultThemeTokens.backgroundPaper,
    },
    text: {
      primary: defaultThemeTokens.textPrimary,
      secondary: defaultThemeTokens.textSecondary,
      disabled: defaultThemeTokens.textDisabled,
    },
  },
  typography: defaultTypography,
  breakpoints: { values: themeCssBreakpoints },
  // Add any other theme customizations here
});

const createForceTheme = (isDark: boolean) => ({
  color: isDark ? defaultThemeTokens.textPrimary : "#222",
  "--mui-palette-background-default": isDark
    ? defaultThemeTokens.backgroundDefault
    : "#fff",
  "--mui-palette-background-paper": isDark
    ? defaultThemeTokens.backgroundPaper
    : "#fafafa",
  "--mui-palette-background-dialog": isDark
    ? defaultThemeTokens.backgroundDialog
    : "#ccc",
  "--mui-palette-background-defaultChannel": isDark ? "0 0 0" : "255 255 255",
  "--mui-palette-text-primary)": isDark ? "#fff" : "#000",
});

export const forceThemeLight = createForceTheme(false);
export const forceThemeDark = createForceTheme(true);
