// app/layout.tsx
"use client";

import { defaultTheme } from "@anthology/shared/src/constants/theme";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  CssVarsThemeOptions,
  experimental_extendTheme as extendTheme,
} from "@mui/material/styles";
import { Provider } from "react-redux";
import { store } from "../features/store";
import "./globals.css";

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const theme = extendTheme({
    ...defaultTheme,
    cssVarPrefix: "mui",
    palette: {
      background: {
        default: "#ffffff",
      },
      text: {
        primary: "#405764",
        secondary: "#505050",
      },
      primary: {
        main: "rgb(0, 116, 146)",
      },
      secondary: {
        main: "#C74840",
        dark: "#C74840",
        light: "#C74840",
      },
    },
  } as CssVarsThemeOptions);

  return (
    <html lang="en">
      <body>
        <Provider store={store}>
          <CssVarsProvider theme={theme} defaultMode="light">
            <CssBaseline />
            {children}
          </CssVarsProvider>
        </Provider>
      </body>
    </html>
  );
}
